<template>
  <div class="foucs">
    <div class="flex justify-between">
      <el-input
        style="width:25%;"
        size="mini"
        placeholder="请输入店铺名称"
        v-model="keyword"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search cursor-pointer"
          @click="onSearch"
        ></i>
      </el-input>
      <div class=" cursor-pointer text-warning " @click="clearFn">
        一键清空
      </div>
    </div>
    <div class="relative flex-1">
      <div class="h-full">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="全部店铺" name="all">
            <FocusList ref="focusAll" type="0" :keyword="keyword" />
          </el-tab-pane>
          <el-tab-pane label="有上新" name="hasNew">
            <FocusList ref="focusNew" type="1" :keyword="keyword" />
          </el-tab-pane>
          <el-tab-pane label="最近看过" name="latelyBrowse">
            <FocusList ref="focusLately" type="2" :keyword="keyword" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import FocusList from "./components/focusList.vue";
export default {
  components: {
    FocusList,
  },
  data() {
    return {
      activeName: "all",
      keyword: "",
    };
  },
  mounted() {
    this.handleClick({ name: "all" });
  },
  methods: {
    onSearch() {
      setTimeout(() => {
        this.handleClick({ name: this.activeName });
      }, 200);
    },
    handleClick(tab) {
      switch (tab.name) {
        case "all":
          if (this.$refs.focusAll) this.$refs.focusAll.loadList();
          break;
        case "hasNew":
          if (this.$refs.focusNew) this.$refs.focusNew.loadList();
          break;
        case "latelyBrowse":
          if (this.$refs.focusLately) this.$refs.focusLately.loadList();
          break;
      }
    },
    clearFn() {
      switch (this.activeName) {
        case "all":
          if (this.$refs.focusAll) this.$refs.focusAll.clearAll();
          break;
        case "hasNew":
          if (this.$refs.focusNew) this.$refs.focusNew.clearAll();
          break;
        case "latelyBrowse":
          if (this.$refs.focusLately) this.$refs.focusLately.clearAll();
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.foucs {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
.relative {
    overflow: hidden;
    /deep/.el-tabs {
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .el-tabs__content {
        flex: 1;
        .el-tab-pane {
          height: 100%;
        }
      }
    }
  }
}
</style>
