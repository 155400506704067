<template>
  <el-popover placement="bottom" width="300" v-model="visibleChat">
    <div>
      <div
        class="flex justify-between"
        v-for="(item, index) in list"
        :key="index"
      >
        <span
          :class="['contact_isonline', item.isOnline ? 'online' : 'offline']"
        >
          {{ item.name }}
        </span>
        <i
          class="iconfont icon-line_message1 text-warning  w-16 h-16 rounded-full cursor-pointer"
          @click.stop="sendMsg(item)"
        ></i>
      </div>
      <div v-if="list.length == 0">暂无联系人</div>
    </div>
    <i class="iconfont icon-line_message1 icon_btn" slot="reference"></i>
  </el-popover>
</template>

<script>
import { getContactStaffs } from "@/api/home/chat";
export default {
  name: "chatDialog",
  props: {
    storeId: {
      type: [String, Number],
      default: 0,
    },
    goodsInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      list: [],
      visibleChat: false,
    };
  },
  watch: {
    storeId(val) {
      if (val) {
        this.initList();
      }
    },
  },
  mounted() {
    this.initList();
  },
  methods: {
    initList() {
      getContactStaffs({
        companyId: this.storeId,
      }).then((res) => {
        this.list = res || [];
      });
    },
    sendMsg(item) {
      if (!this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      this.$store.dispatch("setChatId", {
        ...this.goodsInfo,
        userId: item.userId
      });
      this.$store.dispatch("changeIMVisible", true);
      this.visibleChat = false;
    },
  },
};
</script>

<style lang="less" scoped>
.contact_isonline {
  display: flex;
  align-items: center;
  color: #999999;
  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #999999;
    margin-right: 4px;
    border-radius: 50%;
  }
}
.online {
  color: #666666;
  &::before {
    background: #ff6e4c;
  }
}
.offline {
  opacity: 0.6;
}
.icon_btn {
  padding: 4px;
  background: #ffeeeb;
  border-radius: 50%;
  font-size: 16px;
  color: #ff6e4c;
  cursor: pointer;
}
</style>
