<template>
  <div
    class="storeItem flex justify-between space-x-8 box-border p-16 border border-gray-300 rounded mb-16"
  >
    <el-image
      style="width: 104px; height: 64px"
      class=" rounded-md"
      :src="dataObj.logoImage || defaultImg"
      :fit="'contain'"
    ></el-image>
    <div class="flex-1 space-y-8">
      <div class="flex justify-between">
        <div class="sapce-x-8 flex justify-start items-center mb">
          <span class=" text-lg font-bold inline-block mr-8 store_name">
            {{ dataObj.name }}
          </span>
          <span
            class="text-warning bg-warning bg-opacity-30  rounded-l-full rounded-r-full px-8 inline-block mr-8"
            >{{ renderType }}</span
          >
          <span class=" text-gray-900 text-sm mr-8" v-if="createdAt"
            >{{ createdAt }}关注</span
          >
          <div>
            <span class="text-gray-900 text-sm mr-8">
              <!-- <i class="iconfont icon-line_over text-black-200 "></i> -->
              {{ dataObj.counts.follows || "0" }}人关注
            </span>
            <span class="text-gray-900 text-sm mr-8">
              <!-- <i class="iconfont icon-line_over text-black-200 "></i> -->
              {{ dataObj.counts.browses || "0" }}人浏览
            </span>
            <span class="text-gray-900 text-sm mr-8">
              <!-- <i class="iconfont icon-line_over text-black-200"></i> -->
              {{ dataObj.counts.parts || "0" }}件商品
            </span>
          </div>
        </div>
        <div class="space-x-8">
          <slot name="btn"></slot>
          <!-- <i
            class="iconfont icon-a-asktobuy text-warning bg-warning bg-opacity-30 w-16 h-16 rounded-full"
          ></i>
          <i
            class="iconfont icon-line_over text-black-200 bg-gray-900 bg-opacity-30 w-16 h-16 rounded-full"
          ></i>
          <i
            class="iconfont icon-line_delete text-black-200 bg-gray-900 bg-opacity-30 w-16 h-16 rounded-full"
          ></i> -->
        </div>
      </div>
      <div class="text-black-100 line_2" v-if="dataObj.description">
        {{ dataObj.description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "storeItem",
  props: {
    dataObj: Object,
    createdAt: String,
  },
  data() {
    return {
      defaultImg: require("@/assets/maintenance/default-store.png"),
    };
  },
  computed: {
    renderType() {
      let str = "";
      switch (this.dataObj.type) {
        case "dealer":
          str = "经销商";
          //   this.typeClass = "flag_j";
          break;
        case "garage":
          str = "汽修厂";
          //   this.typeClass = "flag_q";
          break;
        case "manufacturer":
          str = "生产厂家";
          //   this.typeClass = "flag_s";
          break;
      }
      return str;
    },
  },
};
</script>

<style lang="less" scoped>
.storeItem {
  .store_name {
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .line_2 {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 2; //行数
    line-clamp: 2;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
  }
}
</style>
