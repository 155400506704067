<template>
  <div class="focusList">
    <div class="content">
      <template v-for="(item, index) in list">
        <StoreItem
          :dataObj="item.companyInfo"
          :createdAt="item.createdAt"
          :key="index"
        >
          <template #btn>
            <span
              class=" cursor-pointer text-warning"
              @click.stop="takeOff(item)"
            >
              取关
            </span>
            <!-- 联系人 -->
            <ChatDialog :storeId="item.storeId" @click.stop.native />

            <i
              class="iconfont icon-line_home icon_btn"
              @click.stop="toStore(item)"
            ></i>
          </template>
        </StoreItem>
      </template>
      <div class="text-center text-black-200" v-if="!list.length">暂无数据</div>
    </div>

    <el-pagination
      class="mt-8"
      style="text-align:right;"
      layout="total,prev, pager, next"
      :current-page="pageInfo.page"
      :page-size="pageInfo.pageSize"
      :total="pageInfo.totalSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import StoreItem from "@/components/userCenter/storeItem.vue";
import ChatDialog from "../../components/chatDialog.vue";
import {
  getFocusStoreListApi,
  delFocusStoreApi,
  delAllFocusStoreApi,
} from "@/api/focus";

export default {
  name: "focusList",
  components: {
    StoreItem,
    ChatDialog,
  },
  props: {
    keyword: String,
    type: String,
  },
  data() {
    return {
      pageInfo: {
        page: 1,
        pageSize: 20,
        totalSize: 0,
      },
      list: [],
    };
  },
  methods: {
    toStore(item) {
      let obj = {
        path: "/home/store",
        query: {
          storeId: item.storeId,
        },
      };
      this.$router.push(obj);
    },
    loadList() {
      let params = {
        page: this.pageInfo.page,
        pageSize: this.pageInfo.pageSize,
        keyword: this.keyword,
      };
      if (this.type == "1") {
        params.hasNew = true;
      } else if (this.type == "2") {
        params.latelyBrowse = true;
      }
      getFocusStoreListApi(params).then((res) => {
        if (res) {
          this.list = res.rows || [];
          this.pageInfo.totalSize = res.totalSize;
        }
      });
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.pageInfo.page = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.loadList();
    },
    clearAll() {
      let _this = this;
      _this
        .$confirm("确定要删除所有关注的店铺?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          delAllFocusStoreApi().then(() => {
            this.$message.success("删除成功");
            this.loadList(true);
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    takeOff(item) {
      let _this = this;
      _this
        .$confirm("确定要删除该关注的店铺?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let params = {
            all: false,
            id: item.storeId,
          };
          _this.delFocusStoreFn(params);
        })
        .catch(() => {});
    },
    delFocusStoreFn(params) {
      delFocusStoreApi(params).then(() => {
        this.$message.success("删除成功");
        this.loadList(true);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.focusList {
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    overflow: auto;
  }
  .icon_btn {
    padding: 4px;
    background: #ffeeeb;
    border-radius: 50%;
    font-size: 16px;
    color: #ff6e4c;
    cursor: pointer;
  }
}
</style>
