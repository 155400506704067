import request, { SERVICE_MODULE_MAP } from "@/utils/request";

//店铺关注列表
/**
  "page": 0,	 # 页码
  "pageSize": 0,	 # 页宽
  "hasNew": false,	 # 有上新
  "latelyBrowse": false	 # 最近看过
*/
export function getFocusStoreListApi(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/relations/follow-stores",
    method: "get",
    params
  });
}

//关注店铺
/*
"id": 0,	 # 店铺ID
*/
export function addFocusStoreApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/relations/follow-stores/"+ data.id,
    method: "post",
    // data
  });
}

//取消关注店铺
/*
"id": 0,	 # 店铺ID
*/
export function delFocusStoreApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/relations/follow-stores/" + data.id,
    method: "delete",
    // data
  });
}
//取消关注所有店铺
export function delAllFocusStoreApi() {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/relations/follow-stores",
    method: "delete",
  });
}